import axios from "axios";
import * as React from "react";
import CustomScroll from "react-custom-scroll";
import { useParams } from "react-router-dom";
import { useTranslation } from "../../App";
import Show from "../../components/show/show";
import Social from "../../components/social/social";
import { getBgProps } from "../../utils";
import cx from "classnames";

function PersonPage() {

  const params = useParams();
  const [person, setPerson]: [any, (person: any) => void] =
    React.useState({ total: 0, entries: [] });
  const [shows, setShows]: [any, (shows: any) => void] =
    React.useState({ total: 0, entries: [] });
    const [loading, setLoading]: [boolean, (loading: boolean) => void] =
    React.useState<boolean>(true);
    const [loaded, setLoaded]: [boolean, (loading: boolean) => void] =
    React.useState<boolean>(false);
  const [player, setPlayer]: [boolean, (loading: boolean) => void] =
    React.useState<boolean>(false);
  const [error, setError]: [string, (error: string) => void] =
    React.useState("");
    const {t, setCurrentLang} = useTranslation();

  React.useEffect(() => {
    axios
      .post<any>(
        `${process.env.REACT_APP_ROOT_URL}api/collections/get/people`,
        {
          filter: { _id: params.id },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setPerson(response.data.entries[0]);
        setLoading(false);
      });
  }, []);
  React.useEffect(() => {
    if (person?.name) {
      axios
        .post<any>(
          `${process.env.REACT_APP_ROOT_URL}api/collections/get/sets`,
          {
            filter: { author:{_id:person._id} },
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }

        )
        .then((response) => {
          setShows(response.data);
          setLoading(false);
        });
    }
  }, [person]);
 
  return (
    <>
      <div className="faded-y"></div>
      <div className="custom-container px-4 sm:px-16 md:px-20  mx-auto flex-1 py-14">
      <CustomScroll flex="1"  className="custom-scroll-example">
        <div className="flex w-full gap-4 md:gap-8">
          <div className={cx("relative shrink-0  w-[260px]  cursor-pointer aspect-square rounded-full overflow-hidden", { 'gradiant-colors animated': !loaded })} >
            <img
              className="w-full"
              src={`${process.env.REACT_APP_ROOT_URL}storage/uploads${person?.photo?.path}`} onLoad={() => setLoaded(true)}
            />
          </div>
          <div className="flex flex-1 overflow-hidden mt-1 flex-col justify-between items-start text-white">
          <h1 className="text-start text-2xl md:text-3xl md:leading-10 text-ellipsis whitespace-nowrap overflow-hidden w-full">{person.name}</h1>
            <h3 className="text-start text-l md:text-l font-medium text-identityGray-200 md:leading-10">{person.alias}</h3>
            <div className="flex flex-1 mt-3 justify-start gap-4 w-full text-white">
             <p>{person.bio}</p>
            </div>
          </div>
        </div>
      </CustomScroll>

      </div>
    </>
  );
}

export default PersonPage;
