import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Link, NavLink } from "react-router-dom";
import back from "../../../assets/back-b.svg";
import logo from "../../../assets/logo.svg";
import menu from "../../../assets/menu-b.svg";
import cx from 'classnames';
import donate from "../../../assets/donate.svg";

import './navbar.css';
import { usePlayer, useTranslation } from "../../../App";
import CustomScroll from "react-custom-scroll";
import axios from "axios";
import { SocialMediaIcons } from "../../footer/footer";

function Navbar(props : {general : any}) {
  const [menuOpen, setMenuOpen]: [boolean, (error: boolean) => void] = React.useState<boolean>(false);
  const [dropdown, setDropdown]: [boolean, (error: boolean) => void] = React.useState<boolean>(false);
  const [ip, setIP] = React.useState(false);
  const getData = async () => {
    const res = await axios.get("https://api.ipgeolocation.io/ipgeo?apiKey=946de0571beb4c98beefb6f108a2a679&fields=is_eu");
    console.log(res.data);
    setIP(res.data.is_eu);
  };

  React.useEffect(() => {
    //passing getData method to the lifecycle method
    getData();
  }, []);
  const { t, setCurrentLang } = useTranslation();
  const playerContext = usePlayer();
  const changeLang = (lang: string) => {
    setCurrentLang(lang);
    setDropdown(false);
  }
  return (
    <>
      <div className={cx('menu', { 'active': menuOpen })}>
        <div className={cx('menu-inner')}>
          <div className="flex justify-between">
            {/* <button className="flex w-28 justify-around py-0 px-1 items-center opacity-50 hover:opacity-100 text-white border border-white rounded-full text-sm">
              English
              <FontAwesomeIcon icon={faChevronDown} />
            </button> */}
            <div className="relative">
              <button id="dropdownDefault" data-dropdown-toggle="dropdown"
                onClick={() => setDropdown(!dropdown)}
                data-lang={t.getLanguage()}
                className={cx("bg-grey flex w-[6rem] sm:w-28 justify-between py-1 px-2 sm:px-3 pl-6 sm:pl-6 items-center opacity-50 hover:opacity-100 text-white border border-white rounded-2xl text-xs leading-none",
                  { "border-b-transparent border rounded-t-2xl rounded-b-none hover:opacity-50": dropdown }
                )}
                type="button">{t.getString(t.getLanguage())}
                <svg className="w-4 h-4" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg></button>
              <div id="dropdown" className={cx('absolute z-10 w-full flex justify-around py-0 px-0 items-center opacity-50 overflow-hidden text-white border border-t-0 border-white rounded-b-2xl text-sm', { 'hidden': !dropdown })}>
                <ul className="py-0 just text-sm flex-col flex-1 overflow-hidden" aria-labelledby="dropdownDefault">
                  {t.getAvailableLanguages().map((lang: string) =>
                    <li>
                      <a href="#"
                        data-lang={lang}
                        onClick={() => changeLang(lang)}
                        className={cx("block pb-1 px-3 pl-6 hover:bg-identityGray-200 text-white", {
                          "hidden": t.getLanguage() === lang
                        })}>{t.getString(lang)}</a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
            <button className="menu-back-button opacity-50 hover:opacity-100 menu-close" onClick={() => setMenuOpen(false)}>
              <img src={back} />
            </button>
          </div>

          <div className="flex items-start px-0 relative overflow-hidden">
            {/* <CustomScroll flex="1" className="custom-scroll-example"> */}
            <nav className="flex flex-col items-start px-0">
              {/* <NavLink className={({ isActive }) => { return isActive ? 'menu-item active' :  "menu-item"}} onClick={() => setMenuOpen(false)} to="/read">
              {t.getString('menu.read')}
            </NavLink> */}
              <NavLink className={({ isActive }) => { return isActive ? 'menu-item active' : "menu-item" }} onClick={() => setMenuOpen(false)} to="/">
                {t.getString('menu.home')}
              </NavLink>
              {/* <NavLink className={({ isActive }) => { return isActive ? 'menu-item active' :  "menu-item"}} onClick={() => setMenuOpen(false)} to="/events">
            {t.getString('menu.events')}
            </NavLink>
            <NavLink className={({ isActive }) => { return isActive ? 'menu-item active' :  "menu-item"}} onClick={() => setMenuOpen(false)} to="/podcasts">
            {t.getString('menu.podcasts')}
            </NavLink>
            <NavLink className={({ isActive }) => { return isActive ? 'menu-item active' :  "menu-item"}} onClick={() => setMenuOpen(false)} to="/sets">
            {t.getString('menu.sets')}
            </NavLink> */}
              {/* <NavLink className={({ isActive }) => { return isActive ? 'menu-item active' :  "menu-item"}} onClick={() => setMenuOpen(false)} to="/videos">
            {t.getString('menu.videos')}
            </NavLink> */}
              {/* <NavLink className={({ isActive }) => { return isActive ? 'menu-item active' :  "menu-item"}} onClick={() => setMenuOpen(false)} to="/beats">
            {t.getString('menu.beats')}
            </NavLink> */}
              {/* <NavLink className={({ isActive }) => { return isActive ? 'menu-item active' :  "menu-item"}} onClick={() => setMenuOpen(false)} to="/charts">
            {t.getString('menu.charts')}
            </NavLink> */}
              {/* <NavLink className={({ isActive }) => { return isActive ? 'menu-item active' :  "menu-item"}} onClick={() => setMenuOpen(false)} to="/schedule">
            {t.getString('menu.schedule')}
            </NavLink> */}
              {/* <NavLink className={({ isActive }) => { return isActive ? 'menu-item active' :  "menu-item"}} onClick={() => setMenuOpen(false)} to="/people">
            {t.getString('menu.people')}
            </NavLink> */}
              <NavLink className={({ isActive }) => { return isActive ? 'menu-item active' : "menu-item" }} onClick={() => setMenuOpen(false)} to="/contact">
                {t.getString('menu.contacts')}
              </NavLink>
              {/* <NavLink className={({ isActive }) => { return isActive ? 'menu-item active' :  "menu-item"}} onClick={() => setMenuOpen(false)} to="/donate">
            {t.getString('menu.donate')}
            </NavLink> */}
              {
                ip && <NavLink className={({ isActive }) => { return isActive ? 'menu-item active' : "menu-item" }} onClick={() => setMenuOpen(false)} to="/impressum">
                  {t.getString('menu.impressum')}
                </NavLink>
              }
              <NavLink className={({ isActive }) => { return isActive ? 'menu-item active' : "menu-item" }} onClick={() => setMenuOpen(false)} to="/about">
                {t.getString('menu.about')}
              </NavLink>

            </nav>
            {/* </CustomScroll> */}
          </div>


        </div>
        <div>
        <div className="pb-10">
        <SocialMediaIcons general={props.general}></SocialMediaIcons>
        </div>
        <div className="menu-footer">
          {t.getString('menu.trial')}
        </div>
        </div>
      </div>
      <div className="flex flex-col  z-20 w-full">
        <div className="flex items-center justify-between  px-8 py-5 md:py-6 md:px-8  lg:px-20 lg:py-10">
          <div className="flex flex-1">
            <div className="text-center w-[1.4rem] md:w-8 justify-start">
              <button className="overflow-hidden relative h-full w-full opacity-60 hover:opacity-100" onClick={() => setMenuOpen(true)}>
                <img src={menu} className="w-full" />
              </button>
            </div>
          </div>
          <div className="flex flex-1 justify-center">
            <div className="logo-div">
              <NavLink to="/">
                <img src={logo} className="w-full" alt="Logo" />
              </NavLink>
            </div>
          </div>
          <div className="flex flex-1 justify-end">
            <div className="donate-button flex xl:hidden">
              <button id="dropdownDefault" data-dropdown-toggle="dropdown"
                onClick={() => { }}
                className={cx("flex ustify-around py-1 px-1 items-center text-white font-sub text-xl text-identityGray-200",
                )}
                type="button">
                {/* <img src={donate} className="w-8" /> */}
              </button>
            </div>
            <h3 className=" text-identityGray-200 font-sub font-black text-l hidden xl:flex uppercase top-title">
              {t.getString(playerContext.title)}
            </h3>
          </div>
          {/* 
          <nav className="flex items-center">
            <NavLink className="mx-4 font-bold" to="/about">
              About
            </NavLink>
            <NavLink className="mx-4 font-bold" to="/residents">
              Residents
            </NavLink>
            <NavLink className="mx-4 font-bold" to="/guests">
              Guests
            </NavLink>
            <NavLink className="mx-4 font-bold" to="/events">
              Events
            </NavLink>
            <NavLink className="mx-4 font-bold" to="/shows">
              Shows
            </NavLink>
            <NavLink className="mx-4 font-bold" to="/shows">
              Shop
            </NavLink>
          </nav> */}
          {/* <div className="flex">
            <input placeholder="Type to search.." className="mx-2 border rounded-2xl border-black px-3 py-1" />
            <button className="border rounded-full border-black px-2 py-1">
               <FontAwesomeIcon icon={faSearch} />
            </button>
          </div> */}
        </div>
        <div className="mobile-page-title flex-1 p-1 bg-identityGray-300 text-center text-white opacity-70 lg:hidden uppercase top-title-mobile">
          {t.getString(playerContext.title)}
        </div>
      </div>
    </>
  );
}

export default Navbar;
