import "pure-react-carousel/dist/react-carousel.es.css";
import * as React from "react";
import { Link } from "react-router-dom";
import * as Loader from "react-loader-spinner";
import cx from "classnames";
function Schedule(props: any) {
  const [loaded, scheduleLoaded]: [boolean, (loading: boolean) => void] =
    React.useState<boolean>(false);
  return (
    <>
      <Link to="#">
        <div className="flex w-full gap-4 md:gap-8">
        <h3 className="text-start text-l md:text-l font-medium text-identityGray-200 md:leading-tight">{props.schedule.from} - {props.schedule.until}</h3>
          <div className="flex flex-1 overflow-hidden mt-1 flex-col justify-between items-start text-white">
          <h1 className="text-start text-2xl md:text-3xl md:leading-none text-ellipsis whitespace-nowrap overflow-hidden w-full">{props.schedule.title}</h1>
          <h3 className="text-start text-l md:text-l font-medium text-identityGray-200 md:leading-tight">{props.schedule.subtitle}</h3>
          <h3 className="text-start text-l md:text-l font-medium text-identityGray-200 md:leading-tight">Hip Hop, Rap</h3>
           
          </div>
        </div>
      </Link>
    </>
  );
}

export default Schedule;
