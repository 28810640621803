import "pure-react-carousel/dist/react-carousel.es.css";
import * as React from "react";
import { Link } from "react-router-dom";
import * as Loader from "react-loader-spinner";
import cx from "classnames";
function Chart(props: any) {
  const [loaded, chartLoaded]: [boolean, (loading: boolean) => void] =
    React.useState<boolean>(false);
  return (
    <>
      <a href={`${props.chart.link}`} target="_blank">
        <div className="flex items-center w-full gap-4 md:gap-8 border-b border-identityGray-300 pb-8">
          <h2 className=" text-2xl">{props.chart.number} .</h2>
          <div className={cx("relative shrink-0  w-[60px]  cursor-pointer aspect-square rounded-xl overflow-hidden", { 'gradiant-colors animated': !loaded })} >
            <img
              className="w-full"
              src={`${process.env.REACT_APP_ROOT_URL}storage/uploads${props.chart?.photo?.path}`} onLoad={() => chartLoaded(true)}
            />
          </div>
          <div className="flex flex-1 overflow-hidden mt-1 flex-col justify-between items-start text-white">
          <h1 className="text-start text-l md:text-xl md:leading-none text-ellipsis whitespace-nowrap overflow-hidden w-full">{props.chart.title}</h1>
            <h3 className="text-start text-l md:text-l font-medium text-identityGray-200 md:leading-tight">{props.chart.subtitle}</h3>
          </div>
        </div>
      </a>
    </>
  );
}

export default Chart;
