import axios from "axios";
import {
  Slide
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import * as React from "react";
import Event from "../../components/event/event";
import Show from "../../components/show/show";
import InfiniteScroll from 'react-infinite-scroller';
import * as Loader from "react-loader-spinner";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useTranslation } from "../../App";
import Chart from "../../components/chart/chart";
function EventsPage(props: any) {
  const [events, setEvents]: [any, (events: any) => void] =
    React.useState({ total: 0, entries: [] });
  const [page, setPage]: [any, (page: any) => void] =
    React.useState(0);
  const [hasMore, setHasMore]: [any, (hasMore: any) => void] =
    React.useState(true);
  const [pageSize, setPageSize]: [any, (pageSize: any) => void] =
    React.useState(8);
  const [loading, setLoading]: [boolean, (loading: boolean) => void] =
    React.useState<boolean>(false);
  const [error, setError]: [string, (error: string) => void] =
    React.useState("");
    const {t} = useTranslation();
  const loadFunc = (reset = false) => {
    if (!loading) {
      setLoading(true);
      axios
        .post<any>(
          `${process.env.REACT_APP_ROOT_URL}api/collections/get/charts`,
          {
            lang:t.getLanguage(),
            limit: pageSize,
            skip: reset ? 0 : pageSize * page,
            sort: { release_date: 1 },
            filter: {  },
            populate:1
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          const newData = reset ? response.data : { total: response.data.total, entries: [...events.entries, ...response.data.entries] };
          if (reset) {
            setHasMore(true);
          }
          setEvents(newData);
          setPage(reset ? 1 : page + 1);
          setLoading(false);


        });
    }
  }
  React.useEffect(() => {
    loadFunc(true);
  }, [t]);
  React.useEffect(() => {
    if (events.entries.length >= events.total && page) {
      console.log(events.entries.length);
      console.log(events.total);
      setHasMore(false);
    }
  }, [events]);
  const loader = () => {
    return;
  }
  return (
    <>
      <div className="faded-y"></div>
      <div className="custom-container px-4 sm:px-16 md:px-20  mx-auto relative overflow-auto">
          <div className="flex">
          <div className="grid flex-1  grid-cols-1 md:grid-cols-2 gap-10  py-10">
            {events.entries.map((entry: any, index: number) => (
              <Chart key={entry._id} chart={entry} />
            ))}
          </div>
         
          </div>


      </div>
    </>
  );
}

export default EventsPage;
