import { useEffect, useRef, useState } from "react";
import Marquee from "react-fast-marquee";
import cx from 'classnames';

import logo from "../../assets/logo.svg";
import play from "../../assets/play-b.svg";
import pause from "../../assets/pause.svg";
import donate from "../../assets/donate.svg";
import React from "react";
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import "./player.css";
import { usePlayer, useTranslation } from "../../App";
import * as Loader from "react-loader-spinner";

declare const rjq: any;
function createScript(scriptSrc: string, onload: any | null = null) {
  const script = document.createElement("script");
  script.src = scriptSrc;
  script.async = true;
  document.head.appendChild(script);
  script.onload = onload;
}
function updateInfo(data: any) {
  console.log(data);
}
function scriptLoaded(stateSetter: any, props: any) {
  const test = rjq("#rjp-radiojar-player").radiojar("player", {
    streamName: props.general.radioId,
    enableUpdates: true,
    autoplay: false,
  });
  rjq("#rjp-radiojar-player").off("rj-track-load-event");
  rjq("#rjp-radiojar-player").on(
    "rj-track-load-event",
    (event: any, data: any) => {
      updateInfo(data);
      console.log(data);
      stateSetter(data);
    }
  );
}
const setVolume = (event: any) => {
  window.$player.jPlayer('volume', event.target.value);
}
function Player(props: any) {
  const [data, setData] = useState({ artist: "", title: "", thumb: "", album: "", info_urls: "" });
  const [loading, setLoading] = useState(false);
  const [animating, setAnimating]: [boolean, (loading: boolean) => void] =
  React.useState<boolean>(false);
  const [animating1, setAnimating1]: [boolean, (loading: boolean) => void] =
    React.useState<boolean>(false);

  const alterPlayerState = (bool: boolean) => {
    setLoading(true);
    window.setTimeout(() => {
      playerContext.setPlaying(bool);
      setLoading(false);
    }, bool ? 2000 : 0);
  }
  const { t } = useTranslation();
  const playerContext = usePlayer();
  console.log("re-render");
  useEffect(() => {
    if (props.general.radioId) {
      createScript(
        "https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"
      );
      createScript(
        "https://www.radiojar.com/wrappers/api-plugins/v1/radiojar-min.js",
        () => scriptLoaded(setData, props)
      );
    }
  }, [props.general]);
  const ref0 = useRef(document.querySelector('#box') as HTMLDivElement);
  const ref = useRef(document.querySelector('#box') as HTMLDivElement);
  const ref2 = useRef(document.querySelector('#box') as HTMLSpanElement);
  const ref3 = useRef(document.querySelector('#box') as HTMLSpanElement);
  useEffect(() => {
    if (ref.current && ref2.current  && ref3.current) {
      setTimeout(()=>{
        ref2.current.style.minWidth = `${ref0.current.offsetWidth}px`;
      ref3.current.style.minWidth = `${ref0.current.offsetWidth}px`;
         
      ref.current.style.setProperty('--w', `-${2 * ref3.current.offsetWidth}px`);
      ref.current.style.setProperty('--r', `${2 * ref3.current.offsetWidth}`);

      ref0.current.style.setProperty('--w', `-${2 * ref2.current.offsetWidth}px`);
      ref0.current.style.setProperty('--r', `${2 * ref2.current.offsetWidth}`);

      setAnimating(ref2.current.offsetWidth > (ref.current.offsetWidth));
      setAnimating1(ref3.current.offsetWidth > (ref.current.offsetWidth));
      },1000);
     
   
    }
  }, [ref0.current,ref.current, ref2.current, ref3.current, data.title]);
  return (
    <>
      <div id="rj-player" className="w-full text-white">
        <div id="rjp-radiojar-player"></div>
        <div className="player-medium flex flex-col-reverse lg:flex-row gap-5 justify-between items-center pb-5 px-0 lg:px-20 lg:pb-9 lg:py-9  h-full">
          <div className="flex flex-row-reverse justify-between lg:justify-start lg:flex-row  gap-5 sm:gap-11 items-center w-full lg:w-auto px-6 md:px-8 lg:px-0">
            <div className="flex flex-row-reverse lg:flex-row  gap-5 sm:gap-11">
              <div className="flex">
                <button disabled={loading} id="play-button" onClick={() => alterPlayerState(true)} className="jp-play w-14 opacity-40 hover:opacity-100" title="Play">
                  {loading && <>
                    <Loader.ColorRing height="50"
                      width="60"
                      ariaLabel="blocks-loading"
                      wrapperStyle={{}}
                      colors={['#EE4647', '#F69534', '#FBCE07', '#93C066', '#00ADEE']} />
                  </>}
                  {!loading && <>
                    <img src={play} className="w-full" />

                  </>}
                </button>
                <button disabled={loading}  id="pause-button" onClick={() => alterPlayerState(false)} className="jp-pause w-14 opacity-40 hover:opacity-100" title="Pause" style={{ display: 'none' }}>
                  <img src={pause} className="w-full" />
                </button>


              </div>
              <div dir="ltr" className="range-wrapper hidden sm:flex">
                <input type="range" min={0} max={1} step={0.1} defaultValue={1} onChange={(event) => setVolume(event)} />
              </div>
            </div>
            <div className="flex gap-3 sm:gap-11 flex-1">
              <div className="flex">
                <div id="rj-cover" className="w-16 h-16 sm:w-20 sm:h-20  rounded-lg  border border-white overflow-hidden flex items-center gradiant-colors">
                  {data.thumb && <img className="w-full" src={data.thumb} alt="" title=""></img>}
                  {/* {!data.thumb && <div className="bg-identityGray-300 w-full h-full p-4 flex items-center"><img src={logo} alt="" title=""></img></div>} */}
                </div>
              </div>
              <div className="info flex flex-1 md:flex-none">
                <div className="rjp-trackinfo-container text-start flex flex-1 max-w-full">
                  <div className="flex-1 flex flex-col justify-center items-start overflow-hidden whitespace-nowrap">
                    <div className="flex items-center text-white text-sm font-bold leading-2 gap-2 mt-1">

                      {!playerContext.playing && <>
                        <span className="line-height-0 text-identityGray-200 font-ascii">•</span>
                        <span className="font-black text-xss sm:text-xs font-sub cursor-pointer live-text" onClick={() => document.getElementById('play-button')?.click()}>{t.listenToLive}</span>
                      </>
                      }
                      {playerContext.playing && <>
                        <span className="line-height-0  text-liveRed font-ascii">•</span>
                        <span className="font-black text-xss sm:text-xs font-sub live-text">{t.live}</span>
                      </>
                      }


                    </div>
                    <h1 className="text-lg sm:text-xl md:text-2xl mt-0 font-titles w-full main-title">
                      <div ref={ref0} className={cx("marquee big-marquee faded-x", { 'animated': animating })}>
                        {t.getLanguage() === 'ar' &&
                          <div>
                            <span ref={ref2}>{(data.album?.length > 0 && data.album !== "Unknown") ? data.album : data.title}</span>
                            <span style={{minWidth:  ref2.current?.offsetWidth}}>{(data.album?.length > 0 && data.album !== "Unknown") ? data.album : data.title}</span>
                          </div>
                        }
                        {t.getLanguage() !== 'ar' &&
                          <div>
                            <span ref={ref2}>{data.title}</span>
                            <span style={{minWidth:  ref2.current?.offsetWidth}}>{data.title}</span>
                          </div>
                        }
                         {!data.title?.length  &&
                          <div>
                            <span>...</span>
                          </div>
                        }
                      </div>
                    </h1>
                    <h3 id="trackInfo" className="rjp-info truncate text-sm sm:text-base md:text-xl text-white w-full text-ellipsis overflow-hidden artist-name">
                    <div ref={ref} className={cx("marquee faded-x", { 'animated': animating1 })}>
                        {t.getLanguage() === 'ar' &&
                          <div>
                            <span ref={ref3}>{t.getLanguage() === 'ar' && (data.info_urls?.length > 0 ? data.info_urls : data.artist)}</span>
                            <span style={{minWidth:  ref3.current?.offsetWidth}}>{t.getLanguage() === 'ar' && (data.info_urls?.length > 0 ? data.info_urls : data.artist)}</span>
                          </div>
                        }
                        {t.getLanguage() !== 'ar' &&
                          <div>
                            <span ref={ref3}>{t.getLanguage() !== 'ar' && data.artist}</span>
                            <span style={{minWidth:  ref3.current?.offsetWidth}}>{t.getLanguage() !== 'ar' && data.artist}</span>
                          </div>
                        }
                       
                      {!data.title?.length  &&
                          <div>
                            <span>...</span>
                          </div>
                        }
                      </div>
                      
                     
                    </h3>
                  </div>
                </div>
                <div className="rjp-player-container hidden">
                  <div id="rjp-radiojar-player"></div>
                  <div id="rj-player-controls" className="rj-player-controls">
                    <div className="jp-gui jp-interface hidden">
                      <div className="jp-controls">
                        <div className="jp-volume-bar-wrapper">
                          <div className="jp-volume-bar box-content">
                            <div className="jp-volume-bar-value"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-auto flex lg:flex-1 items-center justify-end gap-[30%] bg-identityGray-400 lg:bg-transparent">

            <div className="donate-button hidden xl:flex">
              <button id="dropdownDefault" data-dropdown-toggle="dropdown"
                onClick={() => { }}
                className={cx("flex w-auto justify-around py-1 px-1 items-center text-white font-sub font-black text-l",
                )}
                type="button">
                {/* <img src={donate} className="w-7 mx-2" />
                {t.donate} */}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <div id="rj-player">
        <div className="player-v3 player-medium">
          <div className="info">
            <div className="rjp-trackinfo-container text-start flex">
              <div className="flex justify-center items-start pr-3 py-1">
                <div className="rounded border border-red-500 px-3 pb-0.5 text-red-500 text-md font-bold leading-none	">
                  <span className="mr-1  animate-ping">•</span>Live
                </div>
              </div>
              <div className="flex-1 overflow-hidden whitespace-nowrap">
                <Marquee direction="left" scrollWhen="overflow" speed={0.03}>
                  {data.title}
                </Marquee>
                <p id="trackInfo" className="rjp-info truncate text-xs">
                  {data.artist}
                </p>
              </div>
            </div>
            <div className="rjp-player-container">
              <div id="rjp-radiojar-player"></div>
              <div id="rj-player-controls" className="rj-player-controls">
                <div className="jp-gui jp-interface">
                  <div className="jp-controls">
                    <a href="javascript:;" className="jp-play" title="Play">
                    <FontAwesomeIcon icon={faPlay} />
                    </a>
                    <a href="javascript:;" className="jp-pause" title="Pause" style={{display:'none'}}>
                    <FontAwesomeIcon icon={faPause} />
                    </a>
                    <div className="jp-volume-bar-wrapper">
                      <div className="jp-volume-bar box-content">
                        <div className="jp-volume-bar-value"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="jp-no-solution">
                <span>Update Required</span>
                To play the media you will need to either update your browser to
                a recent version or update your{" "}
                <a href="http://get.adobe.com/flashplayer/" target="_blank">
                  Flash plugin
                </a>
                .
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default Player;
