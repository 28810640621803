import "pure-react-carousel/dist/react-carousel.es.css";
import * as React from "react";
import { Link } from "react-router-dom";
import * as Loader from "react-loader-spinner";
import cx from "classnames";
function Set(props: any) {
  const [loaded, setLoaded]: [boolean, (loading: boolean) => void] =
    React.useState<boolean>(false);
  return (
    <>
      <Link to={`/sets/${props.set._id}`}>
        <div className="flex gap-8">
        <div className={cx("relative shrink-0  w-[110px]  cursor-pointer aspect-square rounded-xl overflow-hidden", { 'gradiant-colors animated': !loaded })} >
            <img
              className="w-full"
              src={`${process.env.REACT_APP_ROOT_URL}storage/uploads${props.set?.photo?.path}`} onLoad={() => setLoaded(true)}
            />
          </div>
          <div className="flex flex-1 overflow-hidden mt-1 flex-col justify-between items-start text-white">
            <h1 className="text-start text-2xl md:text-3xl md:leading-none text-ellipsis whitespace-nowrap overflow-hidden w-full">{props.set.title}</h1>
            <h3 className="text-start text-l md:text-l font-medium text-identityGray-200 md:leading-tight">{props.set.author.name}</h3>
            <div className="flex flex-1 mt-3 justify-start gap-4 w-full text-white">
              <div className="text-start text-base leading-tight">
                <p className=" text-identityGray-200 font-black whitespace-nowrap">Released on:</p>
                {props.set.release_date}
              </div>
              <div className="text-start text-base leading-tight text-ellipsis whitespace-nowrap overflow-hidden">
              <p className=" text-identityGray-200 font-black">Featuring:</p>
                {props.set.feature.map((item: any) => item.name + ', ')}
              </div>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
}

export default Set;
