import axios from "axios";
import * as React from "react";
import CustomScroll from "react-custom-scroll";
import { usePlayer, useTranslation } from "../../App";
import './contact.css';
function ContactPage() {
  const {t, setCurrentLang} = useTranslation();
  const playerContext = usePlayer();

  const [contact, setContact]: [any, (contact: any) => void] = React.useState(
    {}
  );
  const [loading, setLoading]: [boolean, (loading: boolean) => void] =
    React.useState<boolean>(true);
  const [error, setError]: [string, (error: string) => void] =
    React.useState("");
  React.useEffect(() => {
    axios
      .get<any>(`${process.env.REACT_APP_ROOT_URL}api/singletons/get/contact`, {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          lang: t.getLanguage(),
        }
      })
      .then((response) => {
        setContact(response.data);
        setLoading(false);
      });
  }, [t]);
  playerContext.setTitle('menu.contacts');
  return (
    <>
    <div className="faded-y"></div>
    <CustomScroll flex="1" className="custom-scroll-example">
    <div className="custom-container px-4 sm:px-16 md:px-20  mx-auto flex-1">
      <div className="editor-content" dangerouslySetInnerHTML={{ __html: contact.content }}></div>
    </div>
    </CustomScroll>
  </>
  );
}

export default ContactPage;
