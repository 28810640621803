import { LegacyRef, useEffect, useRef, useState } from "react";
import Marquee from "react-fast-marquee";
import cx from 'classnames';

import logo from "../../assets/logo.svg";
import play from "../../assets/play-b.svg";
import pause from "../../assets/pause.svg";
import donate from "../../assets/donate.svg";
import React from "react";
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import "./regular-player.css";
import { usePlayer, useTranslation } from "../../App";
import * as Loader from "react-loader-spinner";
import H5AudioPlayer from "react-h5-audio-player";


function RegularPlayer(props: any) {
  const player: LegacyRef<H5AudioPlayer>  | undefined = React.createRef()
  const [data, setData] = useState({ artist: "", title: "", thumb: "" });
  const [playing, setPlaying] = useState(false);

  const [animating, setAnimating]: [boolean, (loading: boolean) => void] =
    React.useState<boolean>(false);
    const [animating1, setAnimating1]: [boolean, (loading: boolean) => void] =
    React.useState<boolean>(false);
    const { t } = useTranslation();
    const playerContext = usePlayer();
    console.log("re-render");

  const ref0 = useRef(document.querySelector('#box') as HTMLDivElement);
  const ref = useRef(document.querySelector('#box') as HTMLDivElement);
  const ref2 = useRef(document.querySelector('#box') as HTMLSpanElement);
  const ref3 = useRef(document.querySelector('#box') as HTMLSpanElement);
  useEffect(() => {
    if (ref.current && ref2.current  && ref3.current) {
      setTimeout(()=>{
        ref2.current.style.minWidth = `${ref0.current.offsetWidth}px`;
      ref3.current.style.minWidth = `${ref0.current.offsetWidth}px`;
         
      ref.current.style.setProperty('--w', `-${2 * ref3.current.offsetWidth}px`);
      ref.current.style.setProperty('--r', `${2 * ref3.current.offsetWidth}`);

      ref0.current.style.setProperty('--w', `-${2 * ref2.current.offsetWidth}px`);
      ref0.current.style.setProperty('--r', `${2 * ref2.current.offsetWidth}`);

      setAnimating(ref2.current.offsetWidth > (ref.current.offsetWidth));
      setAnimating1(ref3.current.offsetWidth > (ref.current.offsetWidth));
      },1000);
     
   
    }
  }, [ref0.current,ref.current, ref2.current, ref3.current, data.title]);
  const setVolume = (event: any) => {
    console.log(event.target.value);
    console.log(player.current?.lastVolume);
    if(player.current?.audio.current){
      player.current.audio.current.volume = parseFloat(event.target.value);

    }
    
  }
  return (
    <>
      <div id="rj-player" className="w-full text-white">
        <div id="rjp-radiojar-player"></div>
        <div className="player-medium flex flex-col-reverse lg:flex-row gap-5 justify-between items-center pb-5 px-0 lg:px-20 lg:pb-9 lg:py-9  h-full">
          <div className="flex flex-row-reverse justify-between lg:justify-start lg:flex-row  gap-5 sm:gap-11 items-center w-full lg:w-auto px-6 md:px-8 lg:px-0">
            <div className="flex flex-row-reverse lg:flex-row  gap-5 sm:gap-11">
              <div className="flex">
                <button id="play-button" onClick={(e) => player?.current?.togglePlay(e)}  className={cx("jp-play w-14 opacity-40 hover:opacity-100", { 'hidden': playing })} title="Play">
                    <img src={play} className="w-full" />
                </button>
                <button  id="pause-button" onClick={(e) => player?.current?.togglePlay(e)} className={cx("jp-pause w-14 opacity-40 hover:opacity-100", { 'hidden': !playing })} title="Pause">
                  <img src={pause} className="w-full" />
                </button>


              </div>
              <div dir="ltr" className="range-wrapper hidden sm:flex">
                <input type="range" min={0} max={1} step={0.1} defaultValue={1} onChange={(event) => setVolume(event)} />
              </div>
            </div>
            <div className="flex gap-3 sm:gap-11 flex-1">
              <div className="flex">
                <div id="rj-cover" className="w-16 h-16 sm:w-20 sm:h-20  rounded-lg  border border-white overflow-hidden flex items-center gradiant-colors">
                  {data.thumb && <img className="w-full" src={data.thumb} alt="" title=""></img>}
                  {/* {!data.thumb && <div className="bg-identityGray-300 w-full h-full p-4 flex items-center"><img src={logo} alt="" title=""></img></div>} */}
                </div>
              </div>
              <div className="info flex flex-1 md:flex-none">
                <div className="rjp-trackinfo-container text-start flex flex-1 max-w-full">
                  <div className="flex-1 flex flex-col justify-center items-start overflow-hidden whitespace-nowrap">
                    <div className="flex items-center text-white text-sm font-bold leading-2 gap-2 mt-1">

                      {!playerContext.playing && <>
                        <span className="line-height-0 text-identityGray-200 font-ascii">•</span>
                        <span className="font-black text-xss sm:text-xs font-sub cursor-pointer live-text" onClick={() => document.getElementById('play-button')?.click()}>{t.listenToLive}</span>
                      </>
                      }
                      {playerContext.playing && <>
                        <span className="line-height-0  text-liveRed font-ascii">•</span>
                        <span className="font-black text-xss sm:text-xs font-sub live-text">{t.live}</span>
                      </>
                      }


                    </div>
                    <h1 className="text-lg sm:text-xl md:text-2xl mt-0 font-titles w-full main-title">
                      <div ref={ref0} className={cx("marquee big-marquee faded-x", { 'animated': animating })}>
                        {t.getLanguage() === 'ar' &&
                          <div>
                            <span ref={ref2}>{playerContext.audioData.title}</span>
                            <span style={{minWidth:  ref2.current?.offsetWidth}}>{playerContext.audioData.title}</span>
                          </div>
                        }
                        {t.getLanguage() !== 'ar' &&
                          <div>
                            <span ref={ref2}>{data.title}</span>
                            <span style={{minWidth:  ref2.current?.offsetWidth}}>{playerContext.audioData.title}</span>
                          </div>
                        }
                         {!data.title?.length  &&
                          <div>
                            <span>...</span>
                          </div>
                        }
                      </div>
                    </h1>
                    <h3 id="trackInfo" className="rjp-info truncate text-sm sm:text-base md:text-xl text-white w-full text-ellipsis overflow-hidden artist-name">
                    <div ref={ref} className={cx("marquee faded-x", { 'animated': animating1 })}>
                        {t.getLanguage() === 'ar' &&
                          <div>
                            <span ref={ref3}>{t.getLanguage() === 'ar' && (playerContext.audioData.artist)}</span>
                            <span style={{minWidth:  ref3.current?.offsetWidth}}>{t.getLanguage() === 'ar' && (playerContext.audioData.artist)}</span>
                          </div>
                        }
                        {t.getLanguage() !== 'ar' &&
                          <div>
                            <span ref={ref3}>{playerContext.audioData.artist}</span>
                            <span style={{minWidth:  ref3.current?.offsetWidth}}>{playerContext.audioData.artist}</span>
                          </div>
                        }
                       
                      {!data.title?.length  &&
                          <div>
                            <span>...</span>
                          </div>
                        }
                      </div>
                      
                     
                    </h3>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
          <div className="w-full lg:w-auto flex lg:flex-1 items-center justify-between gap-[30%] bg-identityGray-400 lg:bg-transparent">
            <div className="track-container max-w-full flex-1 pr-10 pl-3 lg:pr-0 lg:pl-0 py-2 lg:py-0">
              <AudioPlayer
                ref={player}
                volume={1}
                src={playerContext?.audioData?.src}
                showSkipControls={false}
                autoPlay={true}
                showJumpControls={false}
                footer={null}
                onPause={() =>setPlaying(false)}
                onPlay={() =>setPlaying(true)}
                customControlsSection={[]}
                customProgressBarSection={[RHAP_UI.PROGRESS_BAR, RHAP_UI.CURRENT_TIME, RHAP_UI.DURATION]}
              // Try other props!
              />
            </div>
            <div className="donate-button hidden xl:flex">
              <button id="dropdownDefault" data-dropdown-toggle="dropdown"
                onClick={() => { }}
                className={cx("flex w-auto justify-around py-1 px-1 items-center text-white font-sub font-black text-l",
                )}
                type="button">
                {/* <img src={donate} className="w-7 mx-2" />
                {t.donate} */}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>);
}

export default RegularPlayer;