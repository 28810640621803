import "pure-react-carousel/dist/react-carousel.es.css";
import * as React from "react";
import { Link } from "react-router-dom";
import * as Loader from "react-loader-spinner";

function Show(props: any) {
  const [loaded, setLoaded]: [boolean, (loading: boolean) => void] =
  React.useState<boolean>(false);
  return (
    <>
      <Link to={`/shows/${props.show._id}`}>
        <div className="relative w-full cursor-pointer bg-black aspect-square">
          {!loaded && <div className="w-full aspect-square absolute top-0 left-0 bg-black justify-center items-center flex">
            <Loader.Bars width="20" color="red" ariaLabel="loading-indicator" />
          </div>}
          <div className="text-xs md:text-sm 2xl:text-base absolute w-full font-bold text-white h-full opacity-0 transition duration-300 hover:opacity-100 bg-red-500 flex flex-col justify-center items-center">
            <h3 className="text-center">{props.show.title}</h3>
            <h3 className="text-center">{props.show.date}</h3>
          </div>
          <img
            className="w-full"
            src={`${process.env.REACT_APP_ROOT_URL}storage/uploads${props.show?.thumbnail?.path}`}  onLoad={() => setLoaded(true)}
          />
        </div>
      </Link>
    </>
  );
}

export default Show;
