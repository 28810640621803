import axios from "axios";
import * as React from "react";
import { usePlayer, useTranslation } from "../../App";
import Slider from "../../components/slider/slider";
import CustomScroll from "react-custom-scroll";
import './about.css';
function AboutPage() {
  
  const [about, setAbout]: [any, (about: any) => void] = React.useState(
    {}
  );
  const [loading, setLoading]: [boolean, (loading: boolean) => void] =
    React.useState<boolean>(true);
  const [error, setError]: [string, (error: string) => void] =
    React.useState("");
    const {t, setCurrentLang} = useTranslation();
    const playerContext = usePlayer();
  React.useEffect(() => {
    axios
      .get<any>(`${process.env.REACT_APP_ROOT_URL}api/singletons/get/about`, {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          lang: t.getLanguage(),
        }
      })
      .then((response) => {
        setAbout(response.data);
        setLoading(false);
      });
  }, [t]);
  playerContext.setTitle('menu.about');
  return (
    <>
      <div className="faded-y"></div>
      <CustomScroll flex="1"  className="custom-scroll-example">
      <div className="custom-container px-4 sm:px-16 md:px-20  mx-auto flex-1">
        <div className="editor-content" dangerouslySetInnerHTML={{ __html: about.content }}></div>
      </div>
      </CustomScroll>
    </>
  );

}

export default AboutPage;
