import axios from "axios";
import * as React from "react";
import Slider from "../../components/slider/slider";

function DonatePage() {
  
  const [about, setAbout]: [any, (about: any) => void] = React.useState(
    {}
  );
  const [loading, setLoading]: [boolean, (loading: boolean) => void] =
    React.useState<boolean>(true);
  const [error, setError]: [string, (error: string) => void] =
    React.useState("");
  React.useEffect(() => {
    axios
      .get<any>(`${process.env.REACT_APP_ROOT_URL}api/singletons/get/about`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setAbout(response.data);
        setLoading(false);
      });
  }, []);
  return (
    <>
      <main className="container px-6 max-w-4xl py-20 text-center mx-auto">
        <h2 className="text-2xl text-bold mb-4">Coming Soon</h2>
      </main>
    </>
  );
}

export default DonatePage;
