import axios from "axios";
import * as React from "react";
import CustomScroll from "react-custom-scroll";
import { useParams } from "react-router-dom";
import { useTranslation } from "../../App";
import Show from "../../components/show/show";
import Social from "../../components/social/social";
import { getBgProps } from "../../utils";
import cx from "classnames";

function EventPage() {

  const params = useParams();
  const [event, setEvent]: [any, (event: any) => void] =
    React.useState({ total: 0, entries: [] });
  const [shows, setShows]: [any, (shows: any) => void] =
    React.useState({ total: 0, entries: [] });
  const [loading, setLoading]: [boolean, (loading: boolean) => void] =
    React.useState<boolean>(true);
  const [loaded, setLoaded]: [boolean, (loading: boolean) => void] =
    React.useState<boolean>(false);
  const [player, setPlayer]: [boolean, (loading: boolean) => void] =
    React.useState<boolean>(false);
  const [error, setError]: [string, (error: string) => void] =
    React.useState("");
  const { t, setCurrentLang } = useTranslation();

  React.useEffect(() => {
    axios
      .post<any>(
        `${process.env.REACT_APP_ROOT_URL}api/collections/get/events`,
        {
          filter: { _id: params.id },
          populate: 1
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setEvent(response.data.entries[0]);
        setLoading(false);
      });
  }, []);
  React.useEffect(() => {
    if (event?.name) {
      axios
        .get<any>(
          `${process.env.REACT_APP_ROOT_URL}get-artist-shows`,

          {
            params: { artist: event._id }, headers: {
              "Content-Type": "application/json",
            },
          }

        )
        .then((response) => {
          setShows(response.data);
          setLoading(false);
        });
    }
  }, [event]);

  return (
    <>
      <div className="faded-y"></div>
      <div className="custom-container px-4 sm:px-16 md:px-20  mx-auto flex-1 py-14">
        <CustomScroll flex="1" className="custom-scroll-example">
          <div className="flex w-full gap-4 md:gap-8">
            <div className={cx("relative shrink-0  w-[260px]  cursor-pointer aspect-50/63 rounded-xl overflow-hidden", { 'gradiant-colors animated': !loaded })} >
              <img
                className="w-full"
                src={`${process.env.REACT_APP_ROOT_URL}storage/uploads${event?.photo?.path}`} onLoad={() => setLoaded(true)}
              />
            </div>
            <div className="flex flex-1 overflow-hidden mt-1 flex-col justify-between items-start text-white">
              <h1 className="text-start text-2xl md:text-3xl md:leading-10 text-ellipsis whitespace-nowrap overflow-hidden w-full">{event?.title}</h1>
              <h3 className="text-start text-l md:text-l font-medium text-identityGray-200 md:leading-10">{event?.location}</h3>
              <div className="flex flex-1 mt-3 justify-start gap-4 w-full text-white">
                <p>
                  Vivamus at libero at risus ornare laoreet. Donec cursus mattis porttitor. Aenean vel interdum neque. Mauris non condimentum velit. In tempor est lacus, sit amet laoreet elit aliquam in. Quisque ultrices, nisl ac varius accumsan, mi ipsum tempor odio, eu maximus lectus risus at turpis. Aenean rutrum tortor nec diam dapibus vehicula.
                </p>
              </div>
              <div className="flex items-center flex-1 mt-3 justify-start gap-40 w-full text-white">
                <div className="text-start text-base leading-tight">
                  <p className=" text-identityGray-200 font-black whitespace-nowrap">Location:</p>
                  {event.location}
                </div>
                <div className="text-start text-base leading-tight text-ellipsis whitespace-nowrap overflow-hidden">
                  <p className=" text-identityGray-200 font-black">Date:</p>
                  {event.date} {event.time}
                </div>
              </div>
              <div className="flex items-center flex-1 mt-3 justify-start gap-40 w-full text-white">
                <div className="text-start text-base leading-tight">
                  <p className=" text-identityGray-200 font-black whitespace-nowrap">Status:</p>
                  {event.status}
                </div>
                <div className="text-start r text-base leading-tight text-ellipsis whitespace-nowrap overflow-hidden">
                  <a id="dropdownDefault" data-dropdown-toggle="dropdown"
                    href={event.booking} target="_blank"
                    className={cx("bg-grey flex w-auto justify-between py-1 px-14 items-center opacity-100 text-white border border-white rounded-2xl text-sm"
                    )}
                    type="button">BOOK YOUR TICKET</a>
                </div>
              </div>
            </div>
          </div>
        </CustomScroll>

      </div>
    </>
  );
}

export default EventPage;
