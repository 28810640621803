import axios from "axios";
import {
  Slide
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import * as React from "react";
import Event from "../../components/event/event";
import Show from "../../components/show/show";
import InfiniteScroll from 'react-infinite-scroller';
import * as Loader from "react-loader-spinner";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useTranslation } from "../../App";
function EventsPage(props: any) {
  const [events, setEvents]: [any, (events: any) => void] =
    React.useState({ total: 0, entries: [] });
  const [page, setPage]: [any, (page: any) => void] =
    React.useState(0);
  const [hasMore, setHasMore]: [any, (hasMore: any) => void] =
    React.useState(true);
  const [pageSize, setPageSize]: [any, (pageSize: any) => void] =
    React.useState(8);
  const [loading, setLoading]: [boolean, (loading: boolean) => void] =
    React.useState<boolean>(false);
  const [error, setError]: [string, (error: string) => void] =
    React.useState("");
    const {t} = useTranslation();
  const loadFunc = (reset = false) => {
    if (!loading) {
      setLoading(true);
      axios
        .post<any>(
          `${process.env.REACT_APP_ROOT_URL}api/collections/get/events`,
          {
            lang:t.getLanguage(),
            limit: pageSize,
            skip: reset ? 0 : pageSize * page,
            sort: { release_date: 1 },
            filter: {  },
            populate:1
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          const newData = reset ? response.data : { total: response.data.total, entries: [...events.entries, ...response.data.entries] };
          if (reset) {
            setHasMore(true);
          }
          setEvents(newData);
          setPage(reset ? 1 : page + 1);
          setLoading(false);


        });
    }
  }
  React.useEffect(() => {
    loadFunc(true);
  }, [t]);
  React.useEffect(() => {
    if (events.entries.length >= events.total && page) {
      console.log(events.entries.length);
      console.log(events.total);
      setHasMore(false);
    }
  }, [events]);
  const loader = () => {
    return;
  }
  return (
    <>
      <div className="faded-y"></div>
      <div className="custom-container px-4 sm:px-16 md:px-20  mx-auto relative overflow-auto">
        <InfiniteScroll className="mb-10"
          pageStart={0}
          loadMore={() => { loadFunc(false) }}
          initialLoad={false}
          useWindow={false}
          hasMore={hasMore}
          loader={ <div className="text-center items-center flex justify-center">
            <Loader.ColorRing height="90"
              width="90"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={['#EE4647', '#F69534', '#FBCE07', '#93C066', '#00ADEE']} />
           </div>}
        >
          <div className="flex">
          <div className="grid flex-1  grid-cols-1 gap-10  py-10">
            {events.entries.map((entry: any, index: number) => (
              <Event key={entry._id} event={entry} />
            ))}
          </div>
          <div className="grid  grid-cols-1  py-10">
          <Calendar showDoubleView={false} className="mini-calendar" />
          </div>
          </div>

        </InfiniteScroll>

      </div>
    </>
  );
}

export default EventsPage;
