import axios from "axios";
import {
    Slide
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import * as React from "react";
import Event from "../../components/event/event";
import Show from "../../components/show/show";
import InfiniteScroll from 'react-infinite-scroller';
import * as Loader from "react-loader-spinner";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './beatsPage.css';
import { usePlayer, useTranslation } from "../../App";
import cx from "classnames";
import download from '../../assets/download.svg';
import DataTable from 'react-data-table-component';
import Modal from "../../components/modal/modal";

function EventsPage(props: any) {
    const [events, setEvents]: [any, (events: any) => void] =
        React.useState({ total: 0, entries: [] });
    const [page, setPage]: [any, (page: any) => void] =
        React.useState(0);
    const [hasMore, setHasMore]: [any, (hasMore: any) => void] =
        React.useState(true);
    const [pageSize, setPageSize]: [any, (pageSize: any) => void] =
        React.useState(8);
    const [loading, setLoading]: [boolean, (loading: boolean) => void] =
        React.useState<boolean>(false);
    const [error, setError]: [string, (error: string) => void] =
        React.useState("");
    const { t } = useTranslation();
    const alterPlayerState = (row: any) => {
        playerContext.setAudioData({
            photo: `${process.env.REACT_APP_ROOT_URL}storage/uploads${row?.photo?.path}`,
            src: `${process.env.REACT_APP_ROOT_URL}storage/uploads${row?.file?.path}`
        });
    
        playerContext.setIsAudioPlayer(true);

      }
      const playerContext = usePlayer();
    const columns = [
        {
            name: '',
            selector: (row:any) => row.title,
            cell: (row: any) => {
                return <a onClick={()=> alterPlayerState(row)}>
                <div className="flex items-center w-full gap-4 md:gap-4">
                    <div className={cx("relative shrink-0  w-[60px]  cursor-pointer aspect-square rounded-xl overflow-hidden", { 'gradiant-colors animated': false })} >
                        <img
                            className="w-full"
                            src={`${process.env.REACT_APP_ROOT_URL}storage/uploads${row?.photo?.path}`} onLoad={() => { }}
                        />
                    </div>
                    <div className="flex flex-1 overflow-hidden mt-1 flex-col justify-between items-start text-white">
                        <h1 className="text-start text-base md:text-l md:leading-none text-ellipsis whitespace-nowrap overflow-hidden w-full">{row.title}</h1>
                    </div>
                </div>
            </a>;
            }
        },
        {
            name: 'Artist',
            selector: (row:any) => row.author?.name,
        },
        {
            name: 'Genre',
            selector: (row:any) => row.genre,
        },
        {
            name: 'Mood',
            selector: (row:any) => row.mood,
        },
        {
            name: 'Date',
            selector: (row:any) => row.release_date,
        },
        {
            name: '',
            cell: (row: any) => {
                return <a href={`${process.env.REACT_APP_ROOT_URL}storage/uploads${row?.file?.path}`} target="_blank" className="block w-4">
                <img className="w-full" src={download} />
            </a>;
            }
        },
    ];
    const handleSort = async (column:any, sortDirection:any) => {
        /// reach out to some API and get new data using or sortField and sortDirection
        // e.g. https://api.github.com/search/repositories?q=blog&sort=${column.sortField}&order=${sortDirection}


    };
    const loadFunc = (reset = false) => {
        if (!loading) {
            setLoading(true);
            axios
                .post<any>(
                    `${process.env.REACT_APP_ROOT_URL}api/collections/get/beats`,
                    {
                        lang: t.getLanguage(),
                        limit: pageSize,
                        skip: reset ? 0 : pageSize * page,
                        sort: { release_date: 1 },
                        filter: {},
                        populate: 1
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then((response) => {
                    const newData = reset ? response.data : { total: response.data.total, entries: [...events.entries, ...response.data.entries] };
                    if (reset) {
                        setHasMore(true);
                    }
                    setEvents(newData);
                    setPage(reset ? 1 : page + 1);
                    setLoading(false);


                });
        }
    }
    React.useEffect(() => {
        loadFunc(true);
    }, [t]);
    React.useEffect(() => {
        if (events.entries.length >= events.total && page) {
            console.log(events.entries.length);
            console.log(events.total);
            setHasMore(false);
        }
    }, [events]);
    const loader = () => {
        return;
    }
    const [showModal, setShowModal] = React.useState(true);

    const openModal = () => {
      setShowModal(true);
    };
  
    const closeModal = () => {
      setShowModal(false);
    };
  
    const handleSubmit = () => {
      console.log('Submit button clicked');
      closeModal();
    };
  
    return (
        <>
            <div className="faded-y"></div>
            <div className="custom-container px-4 sm:px-16 md:px-20  mx-auto relative overflow-auto">
                <div className="flex">
                    <DataTable
                        className=" text-white w-full text-left mt-14"
                        columns={columns}
                        data={events.entries}
                        onSort={handleSort}
                        sortServer
                    />

                </div>


            </div>
            <Modal show={showModal} onClose={closeModal}>
                <h1>Modal Content</h1>
                <p>This is a reusable modal!</p>
                <button onClick={closeModal}>Close</button>
                <button onClick={handleSubmit}>Submit</button>
            </Modal>
        </>
    );
}

export default EventsPage;
