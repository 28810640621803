import axios from "axios";
import * as React from "react";
import { createContext, useContext, useMemo } from 'react';
import LocalizedStrings from 'react-localization';
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Navbar from "./components/layout/navbar/navbar";
import PlayerWrapper from "./components/playerWrapper/playerWrapper";
import AboutPage from "./pages/about/about";
import BeatsPage from "./pages/beats/beatsPage";
import ChartsPage from "./pages/charts/chartsPage";
import ContactPage from "./pages/contact/contact";
import DonatePage from "./pages/donate/donatePage";
import EventPage from "./pages/event/eventPage";
import EventsPage from "./pages/events/eventsPage";
import Home from "./pages/home/Home";
import PeoplePage from "./pages/people/peoplePage";
import PersonPage from "./pages/person/personPage";
import PodcastPage from "./pages/podcastPage/podcastPage";
import PodcastsPage from "./pages/podcasts/podcastsPage";
import ReadPage from "./pages/read/read";
import SchedulePage from "./pages/schedule/schedulePage";
import SetsPage from "./pages/sets/setsPage";
import VideosPage from "./pages/videos/videosPage";
import reference from './assets/phone-2.gif';
import ImpressumPage from "./pages/impressum/impressum";
const translations = {
  en: {
    en: "English",
    ar: "عـربي",
    dir: "ltr",
    donate: "DONATE",
    live: "LIVE",
    listenToLive: "LISTEN LIVE",
    menu: {
      home: "Home",
      read: "Read",
      events: "Events",
      podcasts: "Podcasts",
      sets: "Sets",
      videos: "Videos",
      beats: "Beats for Life",
      charts: "Charts",
      schedule: "Schedule",
      people: "People",
      contacts: "Contacts",
      donate: "Donate",
      about: "About us",
      trial:"Live demo",
      impressum: "Impressum"
    },
    footer: 'DJINNI Radio - Arabic underground Music'
  },
  ar: {
    en: "English",
    ar: "عـربي",
    dir: "rtl",
    donate: "مساهمة",
    live: "بث حي",
    listenToLive: "استماع للبث الحي",
    menu: {
      home: "الرئيسية",
      read: "اقـرأ",
      events: "فعاليات",
      podcasts: "بودكـاسـت",
      sets: "سـت",
      videos: "فيديـو",
      beats: "بيتات",
      charts: "حلها",
      schedule: "البرنامح",
      people: "أشخاص",
      contacts: "تواصل معنا",
      donate: "تبرع",
      about: "من نحن",
      trial:"بـث تجريبي",
      impressum: "الشروط والأحكام"
    },
    footer: 'راديو جني - موسيقا عربية مستقلة'
  }
};

const TranslationContext = createContext({} as any);
const PlayerContext = createContext({} as any);

export const useTranslation = () => {
  const context = useContext(TranslationContext)
  if (!context) {
    throw new Error('useTranslation must be used within a TranslationProvider')
  }
  return context
}
export const usePlayer = () => {
  const context = useContext(PlayerContext)
  if (!context) {
    throw new Error('PlayerContext must be used within a PlayerProvider')
  }
  return context
}
const TranslationProvider = ({ translations, ...props }: { translations: any, children: any }) => {
  const [lang, setLang]: [string, (lang: string) => void] = React.useState( localStorage.getItem('preferred-lang') || 'en');

  let t: any;
  if (translations) {
    t = new LocalizedStrings(translations);
    t.setLanguage(lang);
  }
  const value = useMemo(() => t, [t])

  const setCurrentLang = (lang: string) => {
    setLang(lang);
    localStorage.setItem('preferred-lang', lang);
    value.setLanguage(lang);
  }
  return (
    <TranslationContext.Provider value={{ t: value, setCurrentLang: setCurrentLang }} {...props}>
      {props.children}
    </TranslationContext.Provider>
  )
}

const PlayerProvider = ({ children, ...props }: { children: any }) => {
  const [playing, setPlaying]: [boolean, (playing: boolean) => void] =
  React.useState<boolean>(false);
  const [isAudioPlayer, setIsAudioPlayer]: [boolean, (audioPlayer: boolean) => void] =
  React.useState<boolean>(false);
  const [audioData, setAudioData]: [any, (data: any) => void] =
  React.useState<any>(null);
  const [title, setTitle]: [string, (lang: string) => void] = React.useState('menu.home');


  const value = useMemo(() => ({
    setPlaying,
    playing,
    setIsAudioPlayer,
    isAudioPlayer,
    setAudioData,
    audioData,
    setTitle,
    title,
  }), [playing, isAudioPlayer, title, audioData])

 
  return (
    <PlayerContext.Provider value={value} {...props}>
      {children}
    </PlayerContext.Provider>
  )
}

function App() {
  const [general, setGeneral]: [any, (general: any) => void] = React.useState(
    {}
  );
  React.useState<number>(0);
  const [loading, setLoading]: [boolean, (loading: boolean) => void] =
    React.useState<boolean>(true);
  const [error, setError]: [string, (error: string) => void] =
    React.useState("");
  React.useEffect(() => {
    axios
      .get<any>(`${process.env.REACT_APP_ROOT_URL}api/singletons/get/general`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setGeneral(response.data);
        setLoading(false);
      });
  }, []);
  const { t } = useTranslation();

  if (!t.getString) {
    return null;
  }
  console.log(t);
  return (
    <div className="flex flex-col h-screen gap-5 bg-identityGray-500 below-root" lang={t.getLanguage()}>
      <img src={reference} className="reference" />
      <div className="App relative flex-1 flex flex-col">
        {/* <Social /> */}
        <Navbar general={general} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/read" element={<ReadPage />} />
          <Route path="/read/:id" element={<ReadPage />} />
          <Route path="/events" element={<EventsPage />} />
          <Route path="/events/:id" element={<EventPage />} />
          <Route path="/podcasts" element={<PodcastsPage />} />
          <Route path="/podcasts/:id" element={<PodcastPage />} />
          <Route path="/sets" element={<SetsPage />} />
          <Route path="/sets/:id" element={<SetsPage />} />
          <Route path="/videos" element={<VideosPage />} />
          <Route path="/beats" element={<BeatsPage />} />
          <Route path="/charts" element={<ChartsPage />} />
          <Route path="/schedule" element={<SchedulePage />} />
          <Route path="/people" element={<PeoplePage />} />
          <Route path="/people/:id" element={<PersonPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/impressum" element={<ImpressumPage />} />
          <Route path="/donate" element={<DonatePage />} />

        </Routes>
      </div>
      <PlayerWrapper general={general} />
    </div>
  );
}

function AppWrapper() {
  return <TranslationProvider translations={translations}>
    <PlayerProvider>
      <App />
    </PlayerProvider>
  </TranslationProvider>;
}
export default AppWrapper;
