import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/logo.png";
import { usePlayer } from "../../App";
import Player from "../player/player";
import RegularPlayer from "../regular-player/regular-player";
import Footer from "../footer/footer";
function PlayerWrapper(props:{general : object}) {
  const playerContext = usePlayer();

  return (
    <>
          <div className="z-10">
          {playerContext.isAudioPlayer &&
          <div className="player-wrapper flex justify-around bg-identityGray-500  w-full h-30 md:h-37 bottom-0 flex-shrink-0 border-t-2 border-identityGray-400">
            <RegularPlayer  general={props.general}></RegularPlayer>
          </div>
          }
           {!playerContext.isAudioPlayer && 
           <div className="player-wrapper flex justify-around bg-identityGray-500  w-full h-30  bottom-0 flex-shrink-0 border-t-2 border-identityGray-400">
        
          <Player general={props.general} />

          </div>}
            <Footer general={props.general}></Footer>
          </div>
    </>
  );
}

export default PlayerWrapper;
