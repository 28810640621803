import * as React from "react";
import { usePlayer } from "../../App";
import paused from '../../assets/loop-01.mp4';
import played from '../../assets/loop-02.mp4';
import pausedMobile from '../../assets/loop-01-mobile.mp4';
import playedMobile from '../../assets/loop-02-mobile.mp4';
import cx from "classnames";
import './home.css';
function Home() {
  const playerContext = usePlayer();

  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [videoPlayed, setVideoPlayed] = React.useState<any>(played);
  const [videoPaused, setVideoPaused] = React.useState<any>(paused);

  React.useEffect(() => {
    document.body.classList.add('home')
    return () => {
      document.body.classList.remove('home')
    }
  }, [])
 
  React.useEffect(() => {
    if (playerContext.playing) {
      document.getElementById('video-1')?.classList.add('hidden-vid');
      document.getElementById('video-2')?.classList.remove('hidden-vid');
      document.getElementById('noise')?.classList.add('animated');
      window.setTimeout(() => {
        document.getElementById('noise')?.classList.remove('animated');
      }, 1000)
    } else {
     
      document.getElementById('video-2')?.classList.add('hidden-vid');
      document.getElementById('video-1')?.classList.remove('hidden-vid');
      document.getElementById('noise')?.classList.add('animated');
      window.setTimeout(() => {
        document.getElementById('noise')?.classList.remove('animated');
      }, 1000)
    }
  }, [playerContext.playing])
  
  // React.useEffect(() => {
  //   setVideoPlayed( window.innerWidth <= 820 ? playedMobile : played);
  //     setVideoPaused (window.innerWidth <= 820 ? pausedMobile : paused);
  //   const handleWindowSizeChange=() =>{
  //     setVideoPlayed( window.innerWidth <= 820 ? playedMobile : played);
  //     setVideoPaused (window.innerWidth <= 820 ? pausedMobile : paused);
  //   }
  //   window.addEventListener('resize', handleWindowSizeChange);
  //   return () => {
  //     window.removeEventListener('resize', handleWindowSizeChange);
  //   }
  // }, []);
  playerContext.setTitle('menu.home');
  return (
    <>
      <main >
        <video id="video-1" key={videoPaused} onCanPlayThrough={() => setLoaded(true)}  className={cx('videoTag', {'hidden': !loaded})} preload="auto"  autoPlay loop muted>
          <source src={videoPaused} type='video/mp4' />
        </video>
        <video id="video-2" key={videoPlayed}  preload="auto" className='videoTag hidden-vid' autoPlay loop muted>
          <source src={videoPlayed} type='video/mp4' />
        </video>
        
        <div id="noise" className="noise"></div>
      </main>
    </>
  );
}

export default Home;
