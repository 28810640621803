import "pure-react-carousel/dist/react-carousel.es.css";
import * as React from "react";
import { Link } from "react-router-dom";
import * as Loader from "react-loader-spinner";
import cx from "classnames";
function Read(props: any) {
  const [loaded, readLoaded]: [boolean, (loading: boolean) => void] =
    React.useState<boolean>(false);
  return (
    <>
      <Link to={`/reads/${props.read._id}`}>
        <div className="flex w-full gap-4 md:gap-8">
          <div className={cx("relative shrink-0  w-[260px]  cursor-pointer aspect-square rounded-xl overflow-hidden", { 'gradiant-colors animated': !loaded })} >
            <img
              className="w-full"
              src={`${process.env.REACT_APP_ROOT_URL}storage/uploads${props.read?.photo?.path}`} onLoad={() => readLoaded(true)}
            />
          </div>
          <div className="flex flex-1 overflow-hidden mt-1 flex-col justify-between items-start text-white">
          <h1 className="text-start text-2xl md:text-3xl md:leading-10 text-ellipsis whitespace-nowrap overflow-hidden w-full">{props.read.title}</h1>
            <h3 className="text-start text-l md:text-l font-medium text-identityGray-200 md:leading-10">{props.read.author.name}</h3>
            <div className="flex flex-1 mt-3 justify-start gap-4 w-full text-white">
             <p>{props.read.description}</p>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
}

export default Read;
