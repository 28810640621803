import {
  faFacebook,
  faInstagram,
  faPatreon,
  faSoundcloud,
  faSpotify,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import "react-responsive-carousel/lib/styles/carousel.css";
import "./social.css";

function Social(props: any) {
  return (
    <>
      <div className={`flex   z-40 ${props.horizontal ? " flex-row" : "flex-col"} ${props.mobile ? "relative   bg-red-500 justify-around text-xl py-1" : "bg-white  border border-black rounded-l-xl"} ${props.position ? props.position : "fixed right-0 bottom-1/2 -mb-24"} `}>
        {props.general.soundcloud ? (
          <div className="px-0.5">
            <a href={props.general.soundcloud} target="_blank">
              <FontAwesomeIcon icon={faSoundcloud}></FontAwesomeIcon>
            </a>
          </div>
        ) : (
          ""
        )}
        {props.general.instagram ? (
          <div className="px-0.5">
            <a href={props.general.instagram} target="_blank">
              <FontAwesomeIcon icon={faInstagram}></FontAwesomeIcon>
            </a>
          </div>
        ) : (
          ""
        )}
        {props.general.patreon ? (
          <div className="px-0.5">
            <a href={props.general.patreon} target="_blank">
              <FontAwesomeIcon icon={faPatreon}></FontAwesomeIcon>
            </a>
          </div>
        ) : (
          ""
        )}
        {props.general.spotify ? (
          <div className="px-0.5">
            <a href={props.general.spotify} target="_blank">
              <FontAwesomeIcon icon={faSpotify}></FontAwesomeIcon>
            </a>
          </div>
        ) : (
          ""
        )}
        {props.general.facebook ? (
          <div className="px-0.5">
            <a href={props.general.facebook} target="_blank">
              <FontAwesomeIcon icon={faFacebook}></FontAwesomeIcon>
            </a>
          </div>
        ) : (
          ""
        )}
         {props.general.other ? (
          <div className="px-0.5">
            <a href={props.general.other} target="_blank">
              <FontAwesomeIcon icon={faLink}></FontAwesomeIcon>
            </a>
          </div>
        ) : (
          ""
        )}
        {props.general.email ? (
          <div className="px-0.5">
            <a href={'mailto:'+props.general.email} target="_blank">
              <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>
            </a>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default Social;
