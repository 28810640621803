import React, { FC, ReactNode, MouseEvent } from 'react';
import  './modal.css';
interface ModalProps {
  show: boolean;
  onClose: () => void;
  children: ReactNode;
}

const Modal: FC<ModalProps> = ({ show, onClose, children }) => {
  if (!show) {
    return null;
  }

  const stopPropagation = (e: MouseEvent) => {
    e.stopPropagation();
  }

  return (
    <div className="backdrop" onClick={onClose}>
      <div className="modal" onClick={stopPropagation}>
        {children}
      </div>
    </div>
  );
}

export default Modal;