import React, { useContext } from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaYoutube, FaPinterest, FaEnvelope, FaSoundcloud } from 'react-icons/fa';
import './SocialMediaIcons.css';
import { useTranslation } from '../../App';

export const SocialMediaIcons: React.FC<{ general: any }> = (props: { general: any }) => {
  return (
    <div className="social-media-icons">
      {
        props.general?.email?.length > 0 &&
        <a href={`mailto:${props.general.email}`} target="_blank" rel="noopener noreferrer">
          <FaEnvelope />
        </a>
      }
      {
        props.general?.youtube?.length > 0 &&
        <a href={props.general.youtube} target="_blank" rel="noopener noreferrer">
          <FaYoutube />
        </a>
      }
      {
        props.general?.soundcloud?.length > 0 &&
        <a href={props.general.soundcloud} target="_blank" rel="noopener noreferrer">
          <FaSoundcloud />
        </a>
      }

      {
        props.general?.facebook?.length > 0 &&
        <a href={props.general.facebook} target="_blank" rel="noopener noreferrer">
          <FaFacebook />
        </a>
      }
      {
        props.general?.instagram?.length > 0 &&
        <a href={props.general.instagram} target="_blank" rel="noopener noreferrer">
          <FaInstagram />
        </a>
      }
    </div>
  );
}
const Footer: React.FC<{ general: any }> = (props: { general: any }) => {
  const { t } = useTranslation();
  return <div className="player-footer px-8 py-2  md:px-8  lg:px-20  flex flex-col sm:flex-row justify-between text-identityGray-200 bg-identityGray-500  w-full bottom-0 flex-shrink-0 border-t border-identityGray-300">
    <span className="text-xs footer-message font-sub hidden sm:inline">{t.footer}</span>
    <SocialMediaIcons general={props.general}></SocialMediaIcons>
  </div>;
}

export default Footer;