import "pure-react-carousel/dist/react-carousel.es.css";
import * as React from "react";
import { Link } from "react-router-dom";
import * as Loader from "react-loader-spinner";
import cx from "classnames";
function Podcast(props: any) {
  const [loaded, podcastLoaded]: [boolean, (loading: boolean) => void] =
    React.useState<boolean>(false);
  return (
    <>
      <Link to={`/podcasts/${props.podcast._id}`}>
        <div className="flex w-full gap-4 md:gap-8">
          <div className={cx("relative shrink-0  w-[110px]  cursor-pointer aspect-square rounded-xl overflow-hidden", { 'gradiant-colors animated': !loaded })} >
            <img
              className="w-full"
              src={`${process.env.REACT_APP_ROOT_URL}storage/uploads${props.podcast?.photo?.path}`} onLoad={() => podcastLoaded(true)}
            />
          </div>
          <div className="flex flex-1 overflow-hidden mt-1 flex-col justify-between items-start text-white">
          <h1 className="text-start text-2xl md:text-3xl md:leading-none text-ellipsis whitespace-nowrap overflow-hidden w-full">{props.podcast.title} | {props.podcast.episodes.length}</h1>
            <h3 className="text-start text-l md:text-l font-medium text-identityGray-200 md:leading-tight">{props.podcast.author.name}</h3>
            <div className="flex flex-1 mt-3 justify-start gap-4 w-full text-white">
              <div className="text-start text-base leading-tight">
                <p className=" text-identityGray-200 font-black whitespace-nowrap">Broadcast on:</p>
                {props.podcast.release_date}
              </div>
              <div className="text-start text-base leading-tight text-ellipsis whitespace-nowrap overflow-hidden">
                <p className=" text-identityGray-200 font-black">Guests:</p>
                {props.podcast.feature.map((item: any) => item.name + ', ')}
              </div>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
}

export default Podcast;
