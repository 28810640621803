import "pure-react-carousel/dist/react-carousel.es.css";
import * as React from "react";
import { Link } from "react-router-dom";
import * as Loader from "react-loader-spinner";
import cx from "classnames";
function Artist(props: any) {
  const [loaded, setLoaded]: [boolean, (loading: boolean) => void] =
    React.useState<boolean>(false);
  return (
    <>
      <Link to={`/people/${props.artist._id}`}>
        <div>
        <div className={cx("relative w-full max-w-[110px] m-auto cursor-pointer aspect-square rounded-full overflow-hidden", {'gradiant-colors animated':!loaded})} >
          <img
            className="w-full"
            src={`${process.env.REACT_APP_ROOT_URL}storage/uploads${props.artist?.photo?.path}`} onLoad={() => setLoaded(true)}
          />
        </div>
        <div className="flex mt-4 flex-col justify-center items-center text-white">
        <h3 className="text-center text-l md:text-xl font-black">{props.artist.name}</h3>
        <h3 className="text-center text-small md:text-base">{props.artist.alias}</h3>
          </div>
        </div>
      </Link>
    </>
  );
}

export default Artist;
