import axios from "axios";
import * as React from "react";
import CustomScroll from "react-custom-scroll";
import { useParams } from "react-router-dom";
import Show from "../../components/show/show";
import Social from "../../components/social/social";
import { getBgProps } from "../../utils";

function VideosPage() {

    const params = useParams();
    const [resident, setResident]: [any, (resident: any) => void] =
        React.useState({ total: 0, entries: [] });
    const [shows, setShows]: [any, (shows: any) => void] =
        React.useState({ total: 0, entries: [] });
    const [loading, setLoading]: [boolean, (loading: boolean) => void] =
        React.useState<boolean>(true);
    const [player, setPlayer]: [boolean, (loading: boolean) => void] =
        React.useState<boolean>(false);
    const [error, setError]: [string, (error: string) => void] =
        React.useState("");
    React.useEffect(() => {
        axios
            .post<any>(
                `${process.env.REACT_APP_ROOT_URL}api/collections/get/artists`,
                {
                    filter: { _id: params.id },
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((response) => {
                setResident(response.data.entries[0]);
                setLoading(false);
            });
    }, []);
    React.useEffect(() => {
        if (resident?.name) {
            axios
                .get<any>(
                    `${process.env.REACT_APP_ROOT_URL}get-artist-shows`,

                    {
                        params: { artist: resident._id }, headers: {
                            "Content-Type": "application/json",
                        },
                    }

                )
                .then((response) => {
                    setShows(response.data);
                    setLoading(false);
                });
        }
    }, [resident]);


    return (
        <>
            <div className="container mx-auto p-4">
                <CustomScroll flex="1" className="custom-scroll-example">
                    <div className="max-scroller">
                        <div className="flex">
                            <h2 className="flex-1 text-2xl font-bold">{resident.name}</h2>

                        </div>
                        <h2 className="text-xl pt-1">{[resident.Frequency, resident.location].filter(item => item).join(', ')}</h2>
                        <div>
                            <div className="pt-4" dangerouslySetInnerHTML={{ __html: resident.bio }}></div>
                            <div className="mt-4 flex justify-center md:justify-start">
                                <Social general={resident} horizontal={true} position={'relative rounded-xl p-1'} />
                            </div>
                        </div>
                        {shows?.length ? (
                            <div>
                                {<h2 className="text-xl mt-4 -mb-4">Shows</h2>}
                                <div className="grid  grid-cols-2 md:grid-cols-3 2xl:md:grid-cols-4 gap-10  py-10">
                                    {shows.map((entry: any, index: number) => (
                                        <Show key={entry._id} show={entry} />
                                    ))}
                                </div>
                            </div>
                        ) : ''}
                    </div>
                </CustomScroll>
            </div>

        </>
    );
}

export default VideosPage;
